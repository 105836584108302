import { useState } from 'react';
import { notFoundContext } from './notFoundContext';

/**
 * This context provider is used to set the 404 page state.
 */
export const NotFoundProvider = ({ children }) => {
  const [is404Page, setIs404Page] = useState(false);

  return (
    <notFoundContext.Provider value={{ is404Page, setIs404Page }}>
      {children}
    </notFoundContext.Provider>
  );
};
