import { SiteHeaderPill } from './SiteHeaderPill/SiteHeaderPill';

/**
 * The far items for the appbar.
 *
 * > Note: if you need access to hooks, feel free to upgrade
 * > this to a hook (rename to `useFarItems` and make it a
 * > function).
 */
export const farItems = [
  {
    key: 'header',
    // eslint-disable-next-line react/display-name
    onRender: () => <SiteHeaderPill />,
  },
];
