import { Card, CardStandardHeader } from '@coherence-design-system/controls';

/** Standardized card for displaying errors.
 *
 */
export const ErrorCard = ({ error }: { error?: Error }) => (
  <Card header={<CardStandardHeader cardTitle='Something went wrong' />}>
    {/* @ts-ignore */}
    <p>{error && (error.message || error.name)}</p>
  </Card>
);
