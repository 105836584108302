import { MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { DefaultCard } from 'component/DefaultCard';
import { useTranslation } from 'react-i18next';

export const PageReset = () => {
  //suspense disabled because we don't want anything at all to error out this route
  const [t] = useTranslation(['translation', 'title'], { useSuspense: false });
  const onClickReset = () => {
    window.localStorage.clear();
    window.location.pathname = '/';
    window.location.reload();
  };
  const hasLocalStorage = window.localStorage.length > 1;
  const text = hasLocalStorage
    ? t('translation:resetButtonText', 'Reset')
    : t('translation:resetButtonDisabled', 'Reset');

  document.title = t('title:Reset', 'Reset app');

  return (
    <DefaultCard header={<h1>{t('resetTitle', 'Reset app')}</h1>}>
      <p>
        {t(
          'resetHelptext',
          "This page allows you to reset all the filters, settings, and content you've configured on this site in case your app gets into a bad state."
        )}
      </p>

      <PrimaryButton
        disabled={!hasLocalStorage}
        onClick={onClickReset}
        iconProps={{ iconName: 'Clear' }}
      >
        {text}
      </PrimaryButton>
      {!hasLocalStorage && <MessageBarDidntWork />}
    </DefaultCard>
  );
};

const MessageBarDidntWork = () => (
  <MessageBar messageBarType={MessageBarType.success}>
    If you are still having problems and clearing this did not fix the problem,
    please reach out to MSRC
  </MessageBar>
);
