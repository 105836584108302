import { ITheme, IStyle } from '@fluentui/react';

export const makeHtmlBlockStyles = (theme: ITheme): IStyle => ({
  a: {
    color: theme?.semanticColors?.link,
  },
  marginBottom: '0.5em',
  table: {
    boxShadow: theme?.effects?.elevation4,
  },
  'td,th': {
    padding: '1em',
    borderStyle: 'hidden',
  },
  fontSize: '14px',
  pre: {
    border: `ridge 0.15em ${theme?.semanticColors?.disabledBorder}`,
    borderRadius: theme?.effects?.roundedCorner6,
    padding: '0.25em',
    //this slightly thins the size the code frame takes up without overlapping surrounding content
    marginLeft: '-1px',
    marginRight: '-1px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  //don't set styles on the code blocks within a pre block
  'pre code': {
    border: 'none',
  },
  //set styles on code blocks by themselves
  code: {
    border: `ridge 0.1em ${theme?.semanticColors?.disabledBorder}`,
    borderRadius: theme?.effects?.roundedCorner6,
    padding: '0.25em',
    //this slightly thins the size the code frame takes up without overlapping surrounding content
    marginLeft: '-1px',
    marginRight: '-1px',
    fontSize: '16px',
  },
});
