import { convertClassicSugUrlToNew } from 'component/classicSugUrl';
import { regexOldPortalLink } from '../../util/regex/regexOldPortalLink';

/**
 * This rewrites old update guide links.
 */
export const replaceOldUpdateGuide = (text: string | undefined) => {
  return text?.replace(regexOldPortalLink, match =>
    convertClassicSugUrlToNew(match)
  );
};
