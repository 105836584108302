import { useState } from 'react';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import {
  ExternalLink,
  useLocalStorageState,
  useMeSubscriptionListApi,
} from '@msrceng/component';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const MessageNewEmailNotifications = () => {
  const { push, location } = useHistory();
  const [t] = useTranslation('search');
  const { data, isLoading } = useMeSubscriptionListApi();
  const [hide, setHide] = useLocalStorageState(
    'hideNewEmailNotificationsMessage',
    false
  );
  const [hideShortTerm, setHideShortTerm] = useState(false);

  if (
    hide ||
    hideShortTerm ||
    isLoading ||
    // Don't show this if they've already subscribed to email notifications
    (data && data.length > 0) ||
    // Don't show this on the profile page where they configure notifications
    (location.pathname && location.pathname.indexOf('/profile') > -1)
  ) {
    return null;
  }

  return (
    <MessageBar
      messageBarType={MessageBarType.info}
      onDismiss={() => setHideShortTerm(true)}
      dismissButtonAriaLabel={t('Hide for now', {
        defaultValue: 'Hide for now',
        ns: 'search',
      })}
      actions={
        <div>
          <MessageBarButton
            primary={true}
            onClick={() => {
              push({
                ...location,
                pathname: '/profile',
              });
            }}
            iconProps={{ iconName: 'Mail' }}
          >
            {'Go to profile to subscribe'}
          </MessageBarButton>
          <MessageBarButton
            iconProps={{
              iconName: 'Cancel',
            }}
            onClick={() => setHideShortTerm(true)}
          >
            {t('Hide for now', {
              defaultValue: 'Hide for now',
              ns: 'search',
            })}
          </MessageBarButton>
          <MessageBarButton
            iconProps={{ iconName: 'Hide3' }}
            onClick={() => setHide(true)}
          >
            {t("Don't show again", {
              defaultValue: "Don't show again",
              ns: 'search',
            })}
          </MessageBarButton>
        </div>
      }
    >
      <strong>Looking for email notifications?</strong> Please create your
      profile with your preferred email address to sign up for notifications.{' '}
      <ExternalLink href='https://msrc-blog.microsoft.com/2022/01/11/coming-soon-new-security-update-guide-notification-system/'>
        See our blog post for more information
      </ExternalLink>
      .
    </MessageBar>
  );
};
