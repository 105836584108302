import { Card } from '@coherence-design-system/controls';
import { LargeShimmerWithRows } from './largeWithRows';

export const ShimmerCard = () => {
  return (
    <Card
      styles={{
        root: {
          width: '100%',
          minWidth: '5em',
          maxWidth: '100%',
          height: '30em',
          marginBottom: '1em',
          transition: 'all 900ms ease-in-out',
        },
      }}
      disableDrag
      loading
    >
      <LargeShimmerWithRows />
    </Card>
  );
};

export const ShimmerCardStack = () => {
  return (
    <div style={{ padding: '3%', gap: 12 }} title='Loading...'>
      <ShimmerCard />
      <ShimmerCard />
      <ShimmerCard />
      <ShimmerCard />
      <ShimmerCard />
      <ShimmerCard />
      <ShimmerCard />
    </div>
  );
};
