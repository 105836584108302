import { IFeaturePanelFeatureItem } from '@msrceng/component';
import { useFeatureSugAdvisoryTab } from 'feature/advisory/useSugAdvisoryTab';
import { useFeatureNoActionCVE } from 'feature/noActionCVE/useFeatureNoActionCVE';
import { useFeatureCsaf } from './csaf/useFeatureCsaf';

/**
 * Returns the list of feature configurations that are shown in the
 * "Preview" feature panel.
 */
export const useSugFeatureList = (): IFeaturePanelFeatureItem[] => {
  const advisoryTab = useFeatureSugAdvisoryTab();
  const noActionCVE = useFeatureNoActionCVE();
  const csaf = useFeatureCsaf();

  return [csaf, advisoryTab, noActionCVE];
};
