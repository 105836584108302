import { MsrcAuthContextConfig } from '@msrceng/auth';

export const qa: MsrcAuthContextConfig = {
  apiRoot: 'https://api.qa.msrc.windows.net',
  scopes: [
    'openid',
    'profile',
    'https://msrcweb.onmicrosoft.com/msrc-api-ppe/user_impersonation',
  ],
  clientId: '8399de9f-d429-4752-9a4c-5e5366c00d6f',
  authority:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  authorityPasswordReset:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  b2cSignInPolicy: 'B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  applicationInsightsKey: '135ebcbe-c395-48fd-a74f-f65ef482df2c',
  usePopupFlow: false,
};
