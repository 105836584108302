import {
  AppLink,
  IFeaturePanelFeatureItem,
  useFeature,
} from '@msrceng/component';
import { FEATURE_FLAG_CSAF } from './constants';

/**
 * CSAF feature that enables the CSAF file browser at /csaf.
 */
export const useFeatureCsaf = (): IFeaturePanelFeatureItem =>
  useFeature({
    label: 'No Action CVE',
    featureName: FEATURE_FLAG_CSAF,
    isInternalOnly: true,
    description: (
      <p>
        Enables the CSAF file browser at <AppLink href='/csaf'>/csaf</AppLink>.
      </p>
    ),
  });
