import { IFeaturePanelFeatureItem, useFeature } from '@msrceng/component';
import { FEATURE_FLAG_NO_ACTION_CVE } from './constants';

/**
 * TODO: Finish filling out the rest of the description with the rest of the details of the NoActionCVE requirements.
 * No Action CVE feature that adds the No Action CVE to the column list on vulnerabilities tab, all CVE tab. No Action CVE is also included in the search filter list and on the Details page view.
 */
export const useFeatureNoActionCVE = (): IFeaturePanelFeatureItem =>
  useFeature({
    label: 'No Action CVE',
    featureName: FEATURE_FLAG_NO_ACTION_CVE,
    isInternalOnly: true,
    description: (
      <>
        <p>
          Adds the No Action CVE to the column list on vulnerabilities tab, all
          CVE tab. No Action CVE is also included in the search filter list and
          on the Details page view.
        </p>
      </>
    ),
  });
