import { TFunction, i18n } from 'i18next';
import { IContextualMenuItem } from '@fluentui/react';
/** language as a dropdown menu
 *
 * @param {import('i18next').TFunction} t
 * @param {import('i18next').i18n} i18n
 * @returns {import('@fluentui/react').IContextualMenuItem}
 */
export const makeLanguageMenu = (
  t: TFunction,
  i18n: i18n
): IContextualMenuItem => {
  const onItemClick: (event, option?: IContextualMenuItem) => void = (
    e,
    option
  ) => {
    if (option) {
      i18n.changeLanguage(`${option.key}`);
    }
  };

  const itemBase: IContextualMenuItem[] = [
    { key: 'en-us', text: t('translation:language.en-us', 'English (US)') },
    // { key: 'es-es', text: t('translation:language.es-es', "Spanish - Español",), }, //uncomment if we ever get spanish translations
    {
      key: 'de-de',
      text: t('translation:language.de-de', 'German - Deutsch'),
    },
    {
      key: 'fr-fr',
      text: t('translation:language.fr-fr', 'French - Français'),
    },
    {
      key: 'ja-jp',
      text: t('translation:language.ja-jp', 'Japanese - 日本語'),
    },
    { key: 'ko-kr', text: t('translation:language.ko-kr', 'Korean - 한국어') },
    {
      key: 'pt-br',
      text: t('translation:language.pt-br', 'Portuguese - Portugues'),
    },
    {
      key: 'zh-cn',
      text: t(
        'translation:language.zh-cn',
        'Chinese (Simplified) - 中文（简体）'
      ),
    },
    {
      key: 'zh-tw',
      text: t(
        'translation:language.zh-tw',
        'Chinese (Traditional) - 中文（繁体）'
      ),
    },
  ];

  const items = itemBase.map(i => {
    if (i.key.includes('zh')) {
      return {
        ...i,
        checked: i.key === i18n?.language,
      };
    }

    return {
      ...i,
      canCheck: true,
      checked: i.key?.slice(0, 2) === i18n?.language?.slice(0, 2),
    };
  });

  return {
    key: 'language',
    text: t('translation:Language'),
    iconProps: {
      iconName: 'LocaleLanguage',
    },
    shortCut: 'l',
    subMenuProps: {
      title: t('translation:Language'),
      id: 'language-menu',
      items,
      onItemClick,
    },
  };
};
