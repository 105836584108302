import { Breadcrumb, Shimmer } from '@fluentui/react';
import { useThemeData } from '@msrceng/theme';

export const BreadcrumbShimmer = () => {
  const { theme } = useThemeData();

  return (
    <Breadcrumb
      onRenderItem={(props, def) => {
        if (props?.text === 'shimmer') {
          return (
            <div style={{ width: '10em' }}>
              <Shimmer styles={{ shimmerWrapper: { width: '5em' } }} />
            </div>
          );
        }
        return def!(props);
      }}
      items={[
        {
          key: 'MSRC',
          text: 'MSRC',
        },
        {
          key: 'shimmer 1',
          text: 'shimmer',
        },
        {
          key: 'shimmer 2',
          text: 'shimmer',
        },
      ]}
      styles={{
        root: {
          marginTop: 0,
          background: theme?.palette?.neutralLighterAlt,
          boxShadow: theme?.effects?.elevation4,
        },
        list: {
          padding: '0 1.25rem',
          margin: '0 3%',
        },
        item: {
          fontSize: '14px',
        },
        itemLink: {
          fontSize: '14px',
        },
      }}
    />
  );
};
