import {
  Card,
  CardStandardHeader,
  ICardProps,
  ICardStyles,
} from '@coherence-design-system/controls';
import { camelCase } from 'lodash';
import { ITheme } from '@fluentui/react';
import { useThemeData } from '@msrceng/theme';
import { ReactNode } from 'react';

/**
 * Fluent styles generator for the DefaultCard component.
 */
const cardStyles = (theme: ITheme): Partial<ICardStyles> => ({
  root: {
    // backgroundColor: theme?.semanticColors?.cardStandoutBackground,
    transition: 'all 900ms ease-in-out',
  },
});

/**
 * A standard card with a header and children, pre-styled and pre-configured.
 */
export const DefaultCard = ({
  header,
  hideHeader = false,
  children,
  id,
  loading,
}: DefaultCardProps) => {
  const { theme } = useThemeData();
  const htmlId = camelCase(id || header + '') + 'Card';

  return (
    <div
      style={{ width: '100%', display: 'flex' }}
      data-testid={id}
      className='ms-DefaultCard'
      id={id}
    >
      <Card
        data-testid={htmlId}
        styles={cardStyles(theme)}
        header={
          hideHeader ? undefined : (
            <CardStandardHeader cardTitle={header as any} cardTitleId={id} />
          )
        }
        loading={loading}
      >
        {children}
      </Card>
    </div>
  );
};

export interface DefaultCardProps extends Pick<ICardProps, 'loading'> {
  /** The header text or React node to display in the card header */
  header?: string | ReactNode;
  /** Whether to hide the header */
  hideHeader?: boolean;
  /** Contents of the card. */
  children: ReactNode;
  /** HTML ID for anchor and scrollTo behavior. */
  id?: string;
  'data-testid'?: string;
}
