import { ISugConfig } from './shared';

export const prd: ISugConfig = {
  apiRoot: 'https://api.msrc.microsoft.com',
  scopes: [
    'openid',
    'profile',
    'https://msrcweb.onmicrosoft.com/msrc-api/user_impersonation',
  ],
  clientId: '1d13e529-99b8-48fe-a77d-844853ae25bf',
  authority:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_MULTITENANT_SIGNUPSIGNIN',
  authorityPasswordReset:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_MULTITENANT_SIGNUPSIGNIN',
  b2cSignInPolicy: 'B2C_1A_MULTITENANT_SIGNUPSIGNIN',
  applicationInsightsKey: '7d328f90-abe9-4bd6-9cce-a82fa7704e7b',
  usePopupFlow: false,
  apiDocsUrl:
    'https://api.msrc.microsoft.com/cvrf/v3.0/swagger/v3/swagger.json',
};
