/** Take a classic URL and convert it to a modern one
 *
 * @returns newHref
 */
export const convertClassicSugUrlToNew = (classicHref: string): string => {
  if (!classicHref) return classicHref;
  const hash = classicHref.includes('#')
    ? classicHref.substring(classicHref.indexOf('#'))
    : '';
  const asUrl = new URL(classicHref, window.location.origin);

  const alteredPath = asUrl?.pathname
    .replace(/\/http(s)?:\/\/portal.msrc(-ppe)?.microsoft.com/i, '')
    .replace(/\/security-guidance/i, '')
    .replace(new RegExp(process.env.PUBLIC_URL, 'gi'), '')
    // .replace(/\/advisory\//i, '/vulnerability/')
    .replace(/\/releasenotedetail\//i, '/releaseNote/')
    .replace(/\/acknowledgment/i, '/acknowledgement')
    .replace(/\/acknowledgments/i, '/acknowledgement')
    .replace(/\/acknowledgements/i, '/acknowledgement')
    .replace(
      /\/researcher-acknowledgments-online-services/i,
      '/acknowledgement/online'
    );
  const here = window.location.origin + process.env.PUBLIC_URL;
  return here + alteredPath + hash;
};
