import { useMemo } from 'react';
import { makeLanguageMenu } from '../../content';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash';

/**
 * This is the list of items that will appear in the gear
 * (settings) menu.
 */
export const useGearMenuItemList = () => {
  const [t, i18n] = useTranslation(['home', 'translation']);

  const languageMenu = useMemo(() => makeLanguageMenu(t, i18n), [i18n, t]);

  return compact([
    languageMenu,
    i18n.language.startsWith('fr') && {
      key: 'Accessibility: partially compliant',
      text: t(
        'translation:Accessibility',
        'Accessibility: partially compliant'
      ),
      iconProps: { iconName: 'RedEye' },
      href: 'https://www.microsoft.com/fr-fr/accessibility/accessibilite/accessibility-statement',
      rel: 'noopener noreferrer',
      target: '_blank',
    },
  ]);
};
