import { ISugConfig } from './shared';

/** This config is something you can optionally force the app to use if you
 * want to run the API locally on the same machine as the app for debugging
 * purposes.
 */
export const local: ISugConfig = {
  apiRoot: 'localhost:3001',
  scopes: [
    'openid',
    'profile',
    'https://msrcweb.onmicrosoft.com/msrc-api-ppe/user_impersonation',
  ],
  clientId: '1b2765bb-d560-4991-9826-7a8d71e712f4',
  authority:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  authorityPasswordReset:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  b2cSignInPolicy: 'B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  applicationInsightsKey: 'e6b5c4da-5d33-43ce-8cf0-82ba180942a7',
  usePopupFlow: false,
  apiDocsUrl:
    'https://api.msrc-ppe.microsoft.com/CVRFNew/v3.0/swagger/v3/swagger.json',
};
