import { Route } from 'react-router-dom';
import { toLower } from 'lodash';
import { useTranslation } from 'react-i18next';

//Todo: Port to common
const langcodeRegex = '[a-zA-Z]{2,3}-[a-zA-Z]{2,3}';

/**
 * Path for react-router-dom which matches either / or /:lang
 * where lang matches a regex for langcodes
 */
export const pathMatchLangcode = `/:lang(${langcodeRegex})?`;
export const containsLangcodeSegment = pathSegment =>
  new RegExp(langcodeRegex).test(pathSegment);

/**
 * Premade react-router-dom Route which removes langcodes from URL
 */
export const RouteLanguageConnector = () => {
  const { i18n } = useTranslation();
  return (
    <Route
      path={pathMatchLangcode}
      render={({ match }) => {
        if (match !== null) {
          const { lang } = match.params;
          if (
            !!lang &&
            lang.length > 1 &&
            toLower(i18n.language) !== toLower(lang)
          ) {
            i18n.changeLanguage(lang);
          }
        }
        return null;
      }}
    />
  );
};
