import { lazy } from 'react';

export * from './showMore';

export const ShowMoreButton = lazy(() =>
  import('./component').then(c => ({ default: c.ShowMoreButton }))
);
export const ShowMoreDetailsList = lazy(() =>
  import('./component').then(c => ({
    default: c.ShowMoreDetailsList,
  }))
);
