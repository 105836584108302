/// <reference types="@mecontrol/public-api"/>
import { Appbar } from '@msrceng/component';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICommandBarItemProps } from '@fluentui/react';
import {
  portalHomeHref,
  siteName,
  siteHomeHref,
  supportEmailUrl,
} from 'config/shared';
import { useNearItems } from './useNearItems';
import { farItems } from './farItems';
import { useGearMenuItemList } from './useGearMenuItemList';
import { useAppBarStrings } from './useAppBarStrings';

/** An implementation of the `@msrceng/component` `Appbar` specific to the SUG */
export const SugAppbar = () => {
  const history = useHistory();
  const [t] = useTranslation(['home', 'translation']);

  const portalName = t('Security Updates', {
    defaultValue: 'Security Updates',
    ns: 'translation',
  });

  const items: ICommandBarItemProps[] = useNearItems();

  const meControlCommandList: import('@mecontrol/public-api').ICommand[] = [
    {
      id: 'profile',
      text: t('translation:Edit Profile', 'Edit Profile'),
      // @ts-ignore //I don't care that this isn't a link component TS
      type: 'link',
      onClick: () => history.push('/profile'),
    },
  ];

  const gearMenuItemList = useGearMenuItemList();

  const stringsAppBar = useAppBarStrings();

  return (
    <Appbar
      items={items}
      farItems={farItems}
      gearMenuItemList={gearMenuItemList}
      meControlCommandList={meControlCommandList}
      {...{
        portalHomeHref,
        portalName,
        siteName,
        siteHomeHref,
        supportEmailUrl,
        supportFormUrl: 'https://forms.office.com/r/4fMf3fK4P7',
        stringsAppBar,
      }}
    />
  );
};
