import {
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { useAnalytics } from '@msrceng/analytics';
import { slice } from 'lodash';

const context = createContext<IShowMoreContext>(
  null as unknown as IShowMoreContext
);

/**
 * Reusable provider for creating a situation where a given
 * list is able to be expanded.
 */
export const ShowMoreProvider = ({
  items,
  children,
  showMoreCount = 10,
}: {
  items: any[];
  children: any;
  showMoreCount?: number;
}) => {
  const [isExpanded, setExpand] = useState(false);

  /** When should the collapse actually kick in? */
  const isButtonVisible = items?.length > showMoreCount + 3;
  const shouldSlice = !isExpanded && isButtonVisible;

  const itemList = shouldSlice ? slice(items, 0, showMoreCount) : items;

  return (
    <context.Provider
      value={{
        isExpanded,
        setExpand,
        itemList,
        isButtonVisible,
      }}
    >
      {children}
    </context.Provider>
  );
};

/** Hook to retrieve the data from a surrounding ShowMoreProvider
 *
 * @returns {IShowMoreData}
 */
export const useShowMore = () => {
  const ctx = useContext(context);
  if (ctx === null) {
    throw new Error('useShowMore must be used within a ShowMoreProvider');
  }
  const { appInsights } = useAnalytics();
  const { isExpanded, setExpand, isButtonVisible, itemList } = ctx;

  return {
    isExpanded,
    setExpand,
    toggle: () => {
      appInsights?.trackEvent?.({ name: 'Show More clicked' });
      setExpand(!isExpanded);
    },
    items: itemList,
    isButtonVisible,
  };
};

interface IShowMoreContext {
  isExpanded: boolean;
  setExpand: Dispatch<SetStateAction<boolean>>;
  itemList: any[];
  isButtonVisible: boolean;
}

export interface IShowMoreData {
  /** Is the ShowMore expanded? */
  isExpanded: boolean;
  /**  Function to set whether the ShowMore is open(true) or closed(false) */
  setExpand: (isExpanded: boolean) => void;
  /**  Function to toggle the ShowMore open/closed */
  toggle: () => void;
  /**  The resulting item set to pass to your listgrid */
  items: any[];
  /**  Whether to show this showMore's button */
  isButtonVisible: boolean;
}
