import { useEffect, useState } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { useAnalytics } from '@msrceng/analytics';
/** Warning banner that lets the user know they're offline */
export const MessageOffline = () => {
  const [connected, setConnected] = useState('online');
  const { appInsights } = useAnalytics();
  useEffect(() => {
    const onOnline = () => {
      console.info("We're back 🙂📶");
      setConnected('online');
    };
    const onSlow = () => {
      console.info('Slow/weak connection 📶❓');
      setConnected('slow');
    };
    const onOffline = () => {
      console.log('No internet! ❌📶');
      setConnected('offline');
    };
    try {
      window.addEventListener('online', onOnline);
      window.addEventListener('slow', onSlow);
      window.addEventListener('offline', onOffline);
    } catch (e: any) {
      console.error(e);
      appInsights?.trackException?.({
        exception: e,
      });
      setConnected('online');
    }
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('slow', onSlow);
      window.removeEventListener('offline', onOffline);
    };
  }, [appInsights]);

  if (connected === 'offline') {
    return (
      <MessageBar messageBarType={MessageBarType.blocked} title={'Offline'}>
        Your internet is disconnected. Please try again later.
      </MessageBar>
    );
  }
  if (connected === 'slow') {
    return (
      <MessageBar
        messageBarType={MessageBarType.warning}
        title='Warining: weak connection!'
      />
    );
  }

  return null;
};
