import {
  FontSizes,
  HoverCard,
  HoverCardType,
  MessageBar,
  MessageBarType,
  ScreenWidthMaxMedium,
} from '@fluentui/react';
import { useHeaderReadFirstApi } from 'api/header/useHeaderReadFirstApi';
import { ThemedHeaderTagUx } from './ThemedHeaderTagUx';

/**
 * This shows a header that indicates what environment the user is in.
 *
 * (Shows nothing for public prod)
 */
export const SiteHeaderPill = () => {
  const compact = window.innerWidth < ScreenWidthMaxMedium;
  const { data } = useHeaderReadFirstApi();
  const content = data?.headerText;
  if (!content || content.length < 1) {
    return null;
  }
  if (compact) {
    return (
      <HoverCard
        type={HoverCardType.plain}
        plainCardProps={{
          // eslint-disable-next-line react/display-name
          onRenderPlainCard: () => (
            <MessageBar messageBarType={MessageBarType.warning}>
              {content}
            </MessageBar>
          ),
        }}
        cardDismissDelay={300}
        styles={{
          host: {
            maxWidth: '4em',
            display: 'flex',
            alignItems: 'center',
            fontSize: FontSizes.small,
          },
        }}
      >
        <ThemedHeaderTagUx>{content.split(' ').pop()}</ThemedHeaderTagUx>
      </HoverCard>
    );
  }

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ThemedHeaderTagUx>{content}</ThemedHeaderTagUx>
    </div>
  );
};
