import { lazy } from 'react';
// Tiny, and should always load
export { PageReset } from './Reset';

// Lazy loaded stuff
export const PageNotFound = lazy(async () => ({
  default: (await import('./NotFound')).PageNotFound,
}));
export const HomeLayout = lazy(async () => ({
  default: (await import('./Home')).HomeLayout,
}));
export const DeploymentList = lazy(async () => ({
  default: (await import('./Deployment')).DeploymentList,
}));
export const UpdateList = lazy(async () => ({
  default: (await import('./Update')).UpdateList,
}));
export const AcknowledgementLayout = lazy(async () => ({
  default: (await import('./Acknowledgement')).AcknowledgementLayout,
}));
export const ReleaseNoteLayout = lazy(async () => ({
  default: (await import('./ReleaseNote')).ReleaseNoteLayout,
}));
export const ReleaseNotesList = lazy(async () => ({
  default: (await import('./ReleaseNote')).ReleaseNotesList,
}));
export const VulnerabilityDetail = lazy(async () => ({
  default: (await import('./Vulnerability/detail')).VulnerabilityDetail,
}));
export const VulnerabilityList = lazy(async () => ({
  default: (await import('./Vulnerability/list')).VulnerabilityList,
}));
export const Profile = lazy(async () => ({
  default: (await import('./Profile/Profile')).Profile,
}));
export const RevisionNoteList = lazy(async () => ({
  default: (await import('./Revision')).RevisionNoteList,
}));
export const AdvisoryDetail = lazy(async () => ({
  default: (await import('./Advisory/AdvisoryDetail')).AdvisoryDetail,
}));
export const AdvisoryList = lazy(async () => ({
  default: (await import('./Advisory/AdvisoryList')).AdvisoryList,
}));
