import { useTranslation } from 'react-i18next';
import { SugAppbar } from '../SugAppbar/SugAppbar';
import { AppShell } from '@msrceng/component';
import { useAppLinks } from 'component/nav/SugAppShell/useAppLinks';
import {
  portalHomeHref,
  siteName,
  siteHomeHref,
  supportEmailUrl,
} from 'config/shared';
import { useSugFeatureList } from '../../../feature/useSugFeatureList';

/**
 * The app shell implementation for the SUG.
 */
export const SugAppShell = ({ children }) => {
  const [t] = useTranslation(['translation']);

  const appLinks = useAppLinks();
  const featureList = useSugFeatureList();

  const portalName = t('Security Updates', {
    defaultValue: 'Security Updates',
    ns: 'translation',
  });

  return (
    <AppShell
      AppBar={SugAppbar}
      appLinks={appLinks}
      featureList={featureList}
      {...{
        portalHomeHref,
        portalName,
        siteName,
        siteHomeHref,
        supportEmailUrl,
      }}
    >
      {children}
    </AppShell>
  );
};
