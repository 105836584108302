import { useTranslation } from 'react-i18next';

/**
 * Helper for building SUG API URL's and injecting langcode.
 *
 * > Note: SUG uses a language code in the URL, so this has to be a hook.
 */
export const useEndpoint = (
  /** Allows specifying additional pathing. */
  postfix: string,
  /** Allows overriding for API's not part of the core SUG API. */
  prefix: string = '/sug/v2.0/'
) => {
  const [, i18n] = useTranslation('translation', { useSuspense: false });
  return `${prefix}${i18n.language}${postfix}`;
};
