import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AdvisoryDetail,
  HomeLayout,
  ReleaseNoteLayout,
  PageNotFound,
  AcknowledgementLayout,
  ReleaseNotesList,
  VulnerabilityDetail,
  PageReset,
  Profile,
  RevisionNoteList,
} from './component/layout';
import { SugDataContext } from './component/context';
import { SugBreadcrumb, MessageOffline } from './component/nav';
import {
  RouteLanguageConnector,
  pathMatchLangcode,
} from './util/react-router-dom';
import { BaseSuspendedUx } from 'component/shimmer/app';
import {
  AppErrorBoundary,
  CookieConsentBanner,
  InternetExplorerMessageBar,
  useMeEmailIsVerifiedApi,
} from '@msrceng/component';
//Sign in should be immediately available
import { SignIn } from 'component/SignIn';
import { supportEmailUrl } from 'config/shared';
import { RedirectParseClassicUrl } from './component/classicSugUrl';
import { Sticky, StickyPositionType } from '@fluentui/react';
import { SugDownForMaintenance } from './component/nav/SugDownForMaintenance';
import { MessageNewEmailNotifications } from './component/nav/MessageNewEmailNotifications';
import { SugAppShell } from 'component/nav/SugAppShell/SugAppShell';
import { TranslationStartupSuspense } from 'component/shimmer/TranslationStartupSuspense';
// DO NOT DIRECTLY IMPORT PAGE COMPONENTS DIRECTLY HERE!!!
// GO TO `./component/layout/index.ts` AND SET UP LAZY LOADING FOR YOUR NEW PAGE

require('@fluentui/react').initializeIcons();

export const AppLayout = () => {
  const isAccountVerified = useMeEmailIsVerifiedApi().data !== false;

  return (
    <AppErrorBoundary
      {...{
        supportEmailUrl,
      }}
    >
      <Suspense fallback={<BaseSuspendedUx />}>
        <SugAppShell>
          <Sticky isScrollSynced stickyPosition={StickyPositionType.Header}>
            <CookieConsentBanner />
            <MessageOffline />
            <SugDownForMaintenance />
            <InternetExplorerMessageBar />
            <MessageNewEmailNotifications />
          </Sticky>
          <SugBreadcrumb />
          <TranslationStartupSuspense>
            <RouteLanguageConnector />
            <RedirectParseClassicUrl />
            <Switch>
              <Redirect
                from='/(advisories|advisorys)/:stuff'
                to='/advisory/:stuff'
              />
              <Redirect from='/(advisories|advisorys)' to='/advisory' />
              {isAccountVerified && (
                <Route exact path='/profile'>
                  <SignIn>
                    <Profile />
                  </SignIn>
                </Route>
              )}
              <Route
                path={`${pathMatchLangcode}/advisory/:advisoryNumber`}
                component={AdvisoryDetail}
              />
              <Route
                path={`${pathMatchLangcode}/vulnerability/:cveNumber`}
                component={VulnerabilityDetail}
              />
              <Route
                path={`${pathMatchLangcode}/releaseNote/:releaseNumber`}
                component={ReleaseNoteLayout}
              />
              <Route
                path={`${pathMatchLangcode}/acknowledgement`}
                component={AcknowledgementLayout}
              />
              <Route
                path={`${pathMatchLangcode}/releaseNote`}
                component={ReleaseNotesList}
              />
              <Route
                exact
                path={[
                  pathMatchLangcode,
                  '/update',
                  '/updates',
                  '/vulnerability',
                  '/vulnerabilities',
                  '/vulnerabilitys',
                  '/deployment',
                  '/deployments',
                  '/advisory',
                  '/',
                ]}
                component={HomeLayout}
              />

              <Route
                path={[
                  `${pathMatchLangcode}/revision`,
                  `${pathMatchLangcode}/revisionNote`,
                  `${pathMatchLangcode}/revision-note`,
                ]}
                component={RevisionNoteList}
              />
              <Route
                path={`${pathMatchLangcode}/reset`}
                component={PageReset}
              />
              <Route component={PageNotFound} />
            </Switch>
          </TranslationStartupSuspense>
        </SugAppShell>
      </Suspense>
    </AppErrorBoundary>
  );
};

export const App = () => (
  <SugDataContext>
    <AppLayout />
  </SugDataContext>
);
