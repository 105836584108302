import { Suspense, lazy } from 'react';
import { BreadcrumbShimmer } from './BreadcrumbShimmer';

/** Breadcrumb for the SUG that includes nav links back to
 * the parts of portal.msrc.microsoft.com that are external
 * to the SUG.
 */
export const SugBreadcrumb = () => (
  <Suspense fallback={<BreadcrumbShimmer />}>
    <SugBreadcrumbUx />
  </Suspense>
);

export const SugBreadcrumbUx = lazy(
  async () =>
    await import('./SugBreadcrumbUx').then(m => ({
      default: m.SugBreadcrumbUx,
    }))
);
