import { DefaultCard, DefaultCardProps } from './DefaultCard';
import { ReactNode } from 'react';
import {
  CheckboxVisibility,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  IShimmeredDetailsListProps,
} from '@fluentui/react';
import { ShowMoreDetailsList } from '../detailsList';
import { IColumnWithType } from '@msrceng/component';
import { ShowMoreDetailsListProps } from '../detailsList/types';

/**
 * A card with a built in details list pre-configured and pre-styled
 */
export const DetailCard = <T,>({
  header,
  children,
  columns = [],
  items = [],
  detailsListProps = {},
  enableShimmer = false,
  id,
  ariaLabelForGrid,
}: DetailCardProps<T>) => {
  return (
    <DefaultCard header={header} id={id}>
      {children}
      <ShowMoreDetailsList
        enableShimmer={enableShimmer}
        checkboxVisibility={CheckboxVisibility.hidden}
        columns={columns as IColumn[]}
        layoutMode={DetailsListLayoutMode.justified}
        disableSelectionZone
        selectionMode={SelectionMode.none}
        items={items}
        ariaLabelForGrid={ariaLabelForGrid}
        {...detailsListProps}
      />
    </DefaultCard>
  );
};

/**
 * Prop interface for DetailCard
 *
 * @template T - The type of the items[] in the details list.
 */
export interface DetailCardProps<T>
  extends Pick<ShowMoreDetailsListProps, 'enableShimmer' | 'ariaLabelForGrid'>,
    Pick<DefaultCardProps, 'id' | 'header'> {
  /** Any children to render in the card (above the DetailsList). */
  children?: ReactNode;
  /** The columns to render in the DetailsList. */
  columns: Partial<IColumnWithType<T>>[];
  /** The items to render in the DetailsList. */
  items?: T[];
  /** Props to pass to the underlying DetailsList. */
  detailsListProps: Partial<IShimmeredDetailsListProps>;
}
