import { IStringsAppBar } from '@msrceng/component';
import { useTranslation } from 'react-i18next';

/**
 * Gets the strings for the `AppBar` component including any
 * localized ones.
 */
export const useAppBarStrings = (): IStringsAppBar => {
  const [t] = useTranslation(['home', 'translation']);

  return {
    stringsPrivacyItem: {
      text: t('Privacy and cookies', {
        defaultValue: 'Privacy and cookies',
        ns: 'translation',
      }),
    },
    stringsFeedbackItem: {
      text: t('Feedback', {
        defaultValue: 'Feedback and support',
        ns: 'translation',
      }),
    },
    stringsPreviewItem: {
      text: t('Preview', {
        defaultValue: 'Preview',
        ns: 'translation',
      }),
      titlePreview: t('previewTitle', {
        defaultValue: 'Preview new features',
        ns: 'translation',
      }),
      titlePreviewSignedOut: t('previewTitleSignedOut', {
        defaultValue: 'Sign in to preview new features',
        ns: 'translation',
      }),
    },
    stringsThemeItem: {
      itemText: t('Theme', {
        defaultValue: 'Theme',
        ns: 'translation',
      }),
      ThemeNameStrings: {
        LIGHT: t('Light', {
          defaultValue: 'Light',
          ns: 'translation',
        }),
        DARK: t('Dark', {
          defaultValue: 'Dark',
          ns: 'translation',
        }),
        AUTO: t('Auto', {
          defaultValue: 'Automatic - system theme',
          ns: 'translation',
        }),
      },
    },
  };
};
