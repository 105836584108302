import { Suspense } from 'react';
import { Translation } from 'react-i18next';
import { ShimmerCardStack } from 'component/shimmer/card';
import { AppErrorBoundary } from '@msrceng/component';

/**
 * Holds up the app rendering until translations load, and
 * also provide the root suspense.
 */
export const TranslationStartupSuspense = ({ children }) => (
  <AppErrorBoundary>
    <Suspense fallback={<I18NextLoadingScreen />}>
      <Translation>
        {(_t, _i18nOptions, isReady) => {
          if (!isReady) {
            return <I18NextLoadingScreen />;
          }
          return children;
        }}
      </Translation>
    </Suspense>
  </AppErrorBoundary>
);

/**
 * Splash screen for when the app frame/translation suspender is engaged.
 */
const I18NextLoadingScreen = () => <ShimmerCardStack />;
