import {
  AppLink,
  IFeaturePanelFeatureItem,
  useFeature,
} from '@msrceng/component';
import { FEATURE_FLAG_ADVISORY } from './constants';

/**
 * A feature item that adds the new advisories tab and view.
 */
export const useFeatureSugAdvisoryTab = (): IFeaturePanelFeatureItem =>
  useFeature({
    label: 'Advisories tab and view',
    featureName: FEATURE_FLAG_ADVISORY,
    isInternalOnly: true,
    description: (
      <>
        <p>
          Adds new tabs that shows a lists of advisories at:
          <ul>
            <li>
              <AppLink href='/advisory'>/advisory</AppLink>
            </li>
            <li>
              <AppLink href='/advisory/security'>/advisory/security</AppLink>*
            </li>
            <li>
              <AppLink href='/advisory/incident'>/advisory/incident</AppLink>*
            </li>
          </ul>
        </p>
        <p>*These URLs are still in flux and are tentative.</p>
      </>
    ),
  });
