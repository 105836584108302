import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppConfigAndAuthProvider } from '@msrceng/auth';
import { configList } from '../../config';
import { ThemeProvider as LocalThemeProvider } from '@fluentui/react';
import { ThemeProvider } from '@msrceng/theme';
import { AnalyticsSetter } from 'util/AnalyticsSetter';
import { BaseSuspendedUx } from 'component/shimmer/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NotFoundProvider } from './NotFound/NotFoundProvider';

/**
 * This aggregates our data contexts into one wrapper so that App.js doesn't have
 * a million indentations. ➡
 */
export const SugDataContext = ({ children }) => (
  <QueryClientProvider client={new QueryClient()}>
    <ThemeProvider>
      <LocalThemeProvider style={{ width: '100%', height: 'inherit' }}>
        <BrowserRouter basename='/update-guide'>
          <NotFoundProvider>
            <AppConfigAndAuthProvider
              configList={configList}
              fallback={<BaseSuspendedUx />}
            >
              <Suspense fallback={<BaseSuspendedUx />}>
                <AnalyticsSetter>{children}</AnalyticsSetter>
              </Suspense>
            </AppConfigAndAuthProvider>
          </NotFoundProvider>
        </BrowserRouter>
      </LocalThemeProvider>
    </ThemeProvider>
  </QueryClientProvider>
);
