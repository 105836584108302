import { useAuth, useSignInFunction } from '@msrceng/auth';
import { useMsal } from '@azure/msal-react';
import {
  FontSizes,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useThemeData } from '@msrceng/theme';
import { InteractionStatus } from '@azure/msal-browser';
import { DefaultCard } from 'component/DefaultCard';
import { t } from 'i18next';
import { useEffect } from 'react';

/**
 * Message to display when the user is not signed in.
 */
export const SignInUnauthenticatedMessage = () => {
  const { error } = useAuth();
  const onSignIn = useSignInFunction();
  const { inProgress } = useMsal();
  const isLoading = inProgress === InteractionStatus.Login;
  const { theme } = useThemeData();

  useEffect(() => {
    const textSignIn = t('Sign in', {
      defaultValue: 'Sign in',
      ns: 'translation',
    });

    const textSug = t('Security Update Guide', {
      defaultValue: 'Security Update Guide',
      ns: 'title',
    });

    document.title = `${textSignIn} - ${textSug}`;
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '1rem',
      }}
    >
      <div
        style={{
          maxWidth: 1600,
          width: '100%',
        }}
      >
        <DefaultCard>
          <h1>
            {t('Not signed in title', {
              ns: 'translation',
              defaultValue: 'You are not signed in',
            })}
          </h1>
          <p>
            {t('Not signed in message', {
              ns: 'translation',
              defaultValue: 'Please sign in to continue.',
            })}
          </p>
          {error && (
            <MessageBar messageBarType={MessageBarType.error}>
              {error.message}
            </MessageBar>
          )}
          <footer style={{ padding: '2em', textAlign: 'right' }}>
            <PrimaryButton
              styles={{ root: { minWidth: '20em' } }}
              disabled={isLoading}
              onClick={() => onSignIn()}
              iconProps={{ iconName: 'SignIn' }}
            >
              {isLoading ? (
                <Spinner
                  styles={{
                    label: {
                      fontSize: FontSizes.medium,
                      color: theme?.semanticColors?.buttonTextDisabled,
                    },
                    root: {
                      marginLeft: '1em',
                    },
                  }}
                  labelPosition='left'
                  label='Signing you in, please wait'
                  size={SpinnerSize.medium}
                />
              ) : (
                t('Sign in', {
                  defaultValue: 'Sign in',
                  ns: 'translation',
                })
              )}
            </PrimaryButton>
          </footer>
        </DefaultCard>
      </div>
    </div>
  );
};
