import { useThemeData } from '@msrceng/theme';
import { FontSizes } from '@fluentui/react';
import { Tag } from '@msrceng/component';

/**
 * This is the theming for the site header pill.
 */
export const ThemedHeaderTagUx = ({ children }) => {
  const { theme } = useThemeData();
  const { semanticColors } = theme;

  return (
    <Tag
      color={semanticColors.accentButtonText}
      background={semanticColors.accentButtonBackground}
      styles={{
        root: {
          fontSize: FontSizes.medium,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    >
      {children}
    </Tag>
  );
};
