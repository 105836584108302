import { ISugConfig } from './shared';

export const dev: ISugConfig = {
  apiRoot: 'https://api.msrc-ppe.microsoft.com',
  scopes: [
    'openid',
    'profile',
    'https://msrcweb.onmicrosoft.com/msrc-api-ppe/user_impersonation',
  ],
  clientId: '1b2765bb-d560-4991-9826-7a8d71e712f4',
  authority:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  authorityPasswordReset:
    'https://msrcweb.b2clogin.com/msrcweb.onmicrosoft.com/B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  b2cSignInPolicy: 'B2C_1A_TEST_MULTITENANT_SIGNUPSIGNIN',
  applicationInsightsKey: 'e6b5c4da-5d33-43ce-8cf0-82ba180942a7',
  usePopupFlow: false,
  apiDocsUrl:
    'https://api.msrc-ppe.microsoft.com/CVRFNew/v3.0/swagger/v3/swagger.json',
};
