import { AnalyticsProvider } from '@msrceng/analytics';
import { useAppConfig } from '@msrceng/auth';

export const AnalyticsSetter = ({ children }) => {
  const config = useAppConfig();
  const roleName = 'SUG';
  return (
    <AnalyticsProvider
      roleName={roleName}
      applicationInsightsKey={config.applicationInsightsKey}
    >
      {children}
    </AnalyticsProvider>
  );
};
