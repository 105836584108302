import { sanitize } from 'dompurify';
import { flow } from 'lodash';
import { mergeStyles } from '@fluentui/react';
import { useThemeData } from '@msrceng/theme';
import { CSSProperties } from 'react';
import { replaceOldUpdateGuide } from './replaceOldUpdateGuide';
import { makeHtmlBlockStyles } from './makeHtmlBlockStyles';

/**
 * Have some raw HTML that you need to put on the page? Don't use
 * dangerouslySetInnerHtml, use this component instead. It has a built-in
 * sanitizer.
 *
 * > Note: This is custom to SUG because it handles some URL rewrites too.
 */
export const HtmlBlockWithStyles = ({ style, children }: IHtmlBlockProps) => {
  const { theme } = useThemeData();

  const result = flow([
    sanitize,
    replaceOldUpdateGuide,
    // injectLink, //to be added after this is built
  ])(children);
  const className = mergeStyles(makeHtmlBlockStyles(theme));

  return (
    <div
      className={className}
      style={style}
      // This is safe because we sanitize the HTML before injecting it.
      // XSS sanitization is the purpose of this component.
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};

export interface IHtmlBlockProps {
  /** HTML content you want to render. */
  children: string;
  /** Optional style overrides. */
  style?: CSSProperties;
}
