import { setSeoKeywords } from 'component/content/seo';
import { Shimmer } from '@fluentui/react';
import { ShimmerCardStack } from './card';
import { APPBAR_HEIGHT } from '@msrceng/component';
import { SugBreadcrumb } from '../nav';
import { useThemeData } from '@msrceng/theme';

/** Splash screen for when the top level suspender is engaged */
export const BaseSuspendedUx = () => {
  setSeoKeywords();

  const { theme } = useThemeData();

  document.title = '🔃 Security Update Guide - Loading - Microsoft';
  return (
    <div>
      <Shimmer styles={{ shimmerWrapper: { height: APPBAR_HEIGHT } }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          alignItems: 'stretch',
        }}
      >
        <div
          style={{
            background: theme.palette.neutralLighter,
            width: 52,
          }}
        ></div>
        <div
          style={{
            width: '100%',
            flexGrow: 1,
          }}
        >
          <SugBreadcrumb />
          <ShimmerCardStack />
        </div>
      </div>
    </div>
  );
};
