import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const baseKeywords = `Security, Updates, Microsoft, MSFT, \
Update Guide, Security Response Center, CVE, Vulnerabilities, \
Acknowledgements, MSRC, Patch Tuesday, Update Tuesday \
Affected Softwares, Windows, Xbox, Azure, CVSS, Security Research, Security patches, Security updates`;

/** Sets the <meta/> keywords tag from a list. Includes a list of default
 * keywords.
 *
 * > I recommend using useSeoKeywords instead
 *
 * @param {string[]} [keywords] SEO keywords to set (on top of the defaults)
 * @param {string} [base] You probably don't need this
 */
export const setSeoKeywords = (keywords, base = baseKeywords) => {
  let keyString = base;
  if (typeof keywords === 'string') {
    keyString += ', ' + keywords;
  } else if (Array.isArray(keywords) && keywords.length > 0) {
    keyString += ', ' + keywords.join(', ');
  }
  // eslint-disable-next-line no-unused-expressions
  document
    ?.querySelector('meta[name=keywords]')
    ?.setAttribute('content', keyString);
};

/** Hook to set SEO keywords for a given page
 *
 * @param {string} i18nName The name of this page's values in translation.json/keyword
 */
export const useSeoKeywords = i18nName => {
  const [t] = useTranslation(['translation'], { useSuspense: false });
  useEffect(() => {
    /** @type {any} */
    const keyword = t('keyword', { returnObjects: true });
    const list = keyword?.[i18nName];

    if (list) {
      setSeoKeywords(list, keyword?.base || baseKeywords);
    }
  }, [i18nName, t]);
};
