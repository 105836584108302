import { HtmlBlockWithStyles } from '../HtmlBlock';
import { orderBy } from 'lodash';
import { DefaultCard } from './DefaultCard';
import { DefaultCardProps } from '.';
import { ReactNode } from 'react';

/** A card with a built in details list preconfigured and pre-styled */
export const ArticleCard = ({
  header,
  articleList,
  noneText,
  id,
  isLoading,
}: ArticleCardProps) => {
  if (!articleList || articleList.length < 1) {
    if (!isLoading && !noneText) {
      return null;
    }
    return (
      <DefaultCard loading={isLoading} header={header} id={id}>
        {noneText}
      </DefaultCard>
    );
  }
  return (
    <DefaultCard loading={isLoading} header={header} id={id}>
      {orderBy(articleList, ['ordinal', 'title']).map((article, i) => (
        <div key={`card-article-display-${header}-${i}`}>
          {article?.description && (
            <HtmlBlockWithStyles>{article.description}</HtmlBlockWithStyles>
          )}
        </div>
      ))}
    </DefaultCard>
  );
};

export interface ArticleCardArticle {
  description?: string;
}

export interface ArticleCardProps
  extends Pick<DefaultCardProps, 'header' | 'id'> {
  articleList?: ArticleCardArticle[];
  noneText?: ReactNode;
  isLoading?: boolean;
}
