import { Shimmer } from '@fluentui/react';

export const LargeShimmerWithRows = () => {
  const styles = { root: { padding: '1em', borderRadius: '0.5em' } };
  return (
    <div style={{ height: 9000 }}>
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
      <Shimmer styles={styles} />
    </div>
  );
};
