import { useIsAuthenticated } from '@azure/msal-react';
import { useMeEmailIsVerifiedApi } from '@msrceng/component';
import { IAppLinkItem } from '@msrceng/component/lib/AppShell/AppShellContext';
import { compact } from 'lodash';

/**
 * Gets the links to show in the app shell's side nav.
 */
export const useAppLinks = (): IAppLinkItem[] => {
  const isAuthenticatedToApi = useIsAuthenticated();
  /** ONLY goes true if the account is unverified (null is false  ) */
  const isAccountVerified = useMeEmailIsVerifiedApi().data !== false;
  const isVerifiedWithtoken = isAuthenticatedToApi && isAccountVerified;

  return compact([
    isVerifiedWithtoken && {
      iconName: 'Contact',
      name: 'Profile',
      url: '/profile',
    },
  ]);
};
