import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * The items that appear on the left side of the appbar.
 */
export const useNearItems = () => {
  const [t] = useTranslation(['home', 'translation']);
  const { push } = useHistory();

  return [
    {
      key: 'acks',
      title: t('translation:Acknowledgements title', 'Acknowledgements title'),
      text: t('translation:Acknowledgements', 'Acknowledgements'),
      onClick: () => push('/acknowledgement'),
      iconProps: {
        iconName: 'Ribbon',
      },
    },
  ];
};
