import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { SignInUnauthenticatedMessage } from './SignInUnauthenticatedMessage';

/**
 * Component to display the sign in page.
 */
export const SignIn = ({ children }) => {
  return (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInUnauthenticatedMessage />
      </UnauthenticatedTemplate>
    </>
  );
};
