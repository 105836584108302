import { convertClassicSugUrlToNew } from '.';
import { Redirect, useLocation } from 'react-router-dom';

/** Converts classic SUG URL's to new ones */
export const RedirectParseClassicUrl = () => {
  const url = convertClassicSugUrlToNew(window.location.href);
  const location = useLocation();
  if (url !== window.location.href) {
    const destination = new URL(url);
    // Removes "update-guide"
    destination.pathname = destination.pathname.replace(
      new RegExp(process.env.PUBLIC_URL, 'ig'),
      ''
    );

    const to = destination.pathname + destination.search + destination.hash;

    const target = {
      ...location,
      pathname: to,
    };

    return <Redirect to={target} />;
  }
  return null;
};
